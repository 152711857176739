.home {
    padding: 64px 24px;

    img {
        padding-bottom: 32px;
    }

    a {
        color: black;
        cursor: pointer;
        text-decoration: none;

        h1 {
            font-family: "Raleway", sans-serif;
        }

        p {
            color: #444;
        }
    }
}
