.App {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: none;
    text-align: center;
    background-color: #f5f5f5;

    .navbar {
        margin-bottom: 0 !important;
        background-color: #333 !important;

        .navbar-brand {
            color: #eee;
            margin-left: 8px;
        }

        .nav-link {
            color: #eee;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .container {
        padding: 0;
        max-width: none;
    }

    @media only screen and (max-width: 600px) {
        .navbar {
            .navbar-toggler {
                border-color: #777;

                .navbar-toggler-icon {
                    filter: invert(1);
                }
            }
        }
    }
}

.ant-dropdown-trigger {
    .anticon.anticon-down {
        vertical-align: middle;
    }
}
